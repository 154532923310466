const URL = {
    WEBSITE: `https://www.agetimes${process.env.GATSBY_DOMAIN}`,
    FACEBOOK_PAGE: "https://www.facebook.com/Age-Times-102410988886246",
    TWITTER_HANDLE: "https://twitter.com/Age_Times",
    INSTAGRAM_PAGE: "https://www.instagram.com/agetimes_",
    INFO_EMAIL: `info@agetimes${process.env.GATSBY_DOMAIN}`,
    YOUTUBE_CHANNEL: "https://www.youtube.com/channel/UC6CxcUWvPfPKcy3YDibVhJw",
    AGE_HOLDINGS: "https://www.agegroup.io",
    SERVER: "https://age-group-server.herokuapp.com"
}

Object.freeze(URL);

export {URL};
